import React, { useState, useEffect } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import tw from 'twin.macro';
import Image from './Image';
import Link from './Link';
import useKeyPress from '../utils/useKeyPress';
import { useSpring, animated } from 'react-spring';

const order = ['connect', 'predict', 'activate'];

function FeaturesNav({ current, data }) {
  const [isOpen, setIsOpen] = useState(false);
  const [features, setFeatures] = useState(data.allWpFeature.edges.map(({ node }) => node).sort((a, b) => order.indexOf(a.title) + order.indexOf(b.title)));
  const [activeFeature, setActiveFeature] = useState(features.filter(({ id }) => id === current)[0]);
  useEffect(() => {
    setActiveFeature(features.filter(({ id }) => id === current)[0]);
  }, [current]);

  const escPress = useKeyPress('Escape');
  useEffect(() => {
    if (escPress === true) {
      setIsOpen(false);
    }
  }, [escPress]);

  const Subnav = styled.div`
    ${tw`absolute bg-white border`}
    top:0%;
    left: 0;
    right: 0;
    z-index: 51;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    ${activeFeature.title === 'Predict' && `transform:translateY(calc(-33.33333%));`}
    ${activeFeature.title === 'Activate' && `transform:translateY(calc(-66.6666%));`}
  `;

  const Header = styled.div`
    ${tw`absolute border border-black`}
    bottom:100%;
    left: -1px;
    right: -1px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  `;

  const SubnavAnimation = useSpring({
    opacity: isOpen ? 1 : 0.5,
    from: { opacity: 0.5 },
  });

  const SlideAnimation = useSpring({
    x: isOpen ? 22.5 : 0,
  });

  return (
    <div>
      {isOpen && <div className="fixed inset-0 bg-white z-10 opacity-20" onClick={() => setIsOpen(false)}></div>}
      <animated.div style={{ transform: SlideAnimation.x.interpolate((x) => `translateX(${x}px)`) }} className={`relative inline-block mb-6 -ml-5 z-40`}>
        <div
          onClick={(e) => {
            e.preventDefault();
            setIsOpen(!isOpen);
          }}
          className={`cursor-pointer py-3 bg-white`}
        >
          <Item {...activeFeature} isCurrent={true} isOpen={isOpen} />
        </div>

        {isOpen && (
          <animated.div style={SubnavAnimation}>
            <Subnav>
              <div className="relative">
                <Header className="bg-black cursor-pointer select-none text-white p-2 font-semibold text-12px text-center uppercase relative" onClick={() => setIsOpen(false)}>
                  <div>Features</div>
                  <svg className="absolute" width="12" height="12" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" style={{ transform: 'translateY(-50%) rotate(180deg)', top: '50%', right: '.8rem' }}>
                    <g transform="translate(.209 .196)" fill="none" fillRule="evenodd">
                      <circle fill="#fff" cx="8.689" cy="8.689" r="8.689" />
                      <path stroke="#0D0D0D" strokeWidth="1.464" d="M5.91079624 7.54030379L8.72160424 10.3511118 11.5324122 7.54030379" />
                    </g>
                  </svg>
                </Header>
                <div>
                  {features.map((feature, i) => (
                    <Item key={feature.id} {...feature} isOpen={isOpen} className={`py-3`} />
                  ))}
                </div>
              </div>
            </Subnav>
            <div onClick={() => setIsOpen(false)} className="fixed inset-0 bg-white opacity-25" style={{ zIndex: '-1' }} />
          </animated.div>
        )}
      </animated.div>
    </div>
  );
}

function Item({ uri, featurePage, title, isCurrent = false, className, isOpen, style = {} }) {
  const map = {
    blue: 'accent',
  };
  return (
    <Link
      onClick={(e) => {
        if (isCurrent) {
          e.preventDefault();
          return false;
        }
      }}
      to={uri}
      className={`${isOpen ? 'opacity-75 hover:opacity-100' : ''} px-5 flex items-center font-semibold transition duration-100 ${className || ''} ${!isCurrent && false && `hover:text-${map[featurePage.colour] || featurePage.colour || 'accent'}`}`}
      style={style}
    >
      <Image data={featurePage.icon} style={{ width: '37px', height: '37px' }} />
      <div className="mx-3">{title}</div>
      {isCurrent && !isOpen && (
        <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
          <g transform="translate(.209 .196)" fill="none" fillRule="evenodd">
            <circle fill="#0D0D0D" cx="8.689" cy="8.689" r="8.689" />
            <path stroke="#FFF" strokeWidth="1.464" d="M5.91079624 7.54030379L8.72160424 10.3511118 11.5324122 7.54030379" />
          </g>
        </svg>
      )}
    </Link>
  );
}
const query = graphql`
  {
    allWpFeature {
      edges {
        node {
          id
          uri
          title
          featurePage {
            colour
            icon {
              id
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`;

export default (props) => <StaticQuery query={`${query}`} render={(data) => <FeaturesNav data={data} {...props} />} />;
