import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Link from '../components/Link';
import SEO from '../components/SEO';
import TwoColumns from '../components/TwoColumns';
import Image from '../components/Image';
import FeatureContentGrid from '../components/FeatureContentGrid';
import FeaturedCustomer from '../components/FeaturedCustomer';
import NextPage from '../components/NextPage';
import FeaturesNav from '../components/FeaturesNav';
import ConnectAni from '../components/ConnectAni';
import Lottie from '../components/Lottie';
import ActivateAni from '../assets/animations/product-activate';
import PredictAni from '../assets/animations/product-predict-hero';
import InViewMonitor from 'react-inview-monitor';
import styled from 'styled-components';
import media from '../utils/media';

const Hero = styled.section`
  .heighty-bit {
    ${media.greaterThan('lg')`
      height: 556px;
      > .h-full > div {
        height:100%;
      }
      svg {
        max-height:100%;
      }
    `}
  }
`;
export default class Feature extends Component {
  constructor(props) {
    super(props);
    this.state = {
      predictPlaying: false,
    };
  }
  render() {
    const { seo, id } = this.props.data.wpFeature;
    const { hero, content, next } = this.props.data.wpFeature.featurePage;
    const isConnectAni = hero.image.localFile.publicURL.includes('connect-graphic');
    const isActivateAni = hero.image.localFile.publicURL.includes('activate');
    const isPredictAni = hero.image.localFile.publicURL.includes('predict');

    return (
      <Layout>
        <SEO seo={seo} bodyAttributes={{ class: 'page--feature' }} />

        {/* Hero */}
        <Hero className="pad-tb--sm md:pad-tb--xs">
          <div className="container">
            <TwoColumns
              gutters="gap-10"
              text={
                <div className="text-card md:max-w-md">
                  <FeaturesNav current={id} />
                  <h3 className="text-card__heading text-58px">{hero.textCard.heading}</h3>
                  <div className="text-card__content md:max-w-sm" dangerouslySetInnerHTML={{ __html: hero.textCard.content }} />

                  {hero.textCard.button.title.length && (
                    <Link className="text-link text-link--black" to={hero.textCard.button.url}>
                      {hero.textCard.button.title}
                    </Link>
                  )}
                </div>
              }
              image={
                <div className="heighty-bit">
                  {isActivateAni && <Lottie data={ActivateAni} />}
                  {isConnectAni && (
                    <div className="h-full">
                      <ConnectAni />
                    </div>
                  )}
                  {isPredictAni && (
                    <InViewMonitor
                      key={`predict-hero-monitor`}
                      classNameNotInView="w-full h-full"
                      classNameInView="w-full h-full"
                      onInView={() => {
                        this.setState({ predictPlaying: true });
                      }}
                    >
                      <Lottie data={PredictAni} isStopped={!this.state.predictPlaying} options={{ loop: false, autoplay: false }} className="h-full" />
                    </InViewMonitor>
                  )}
                </div>
              }
            />
          </div>
        </Hero>

        {content.map((section, index) => (
          <section key={`${section.__typename}-${index}`}>
            <RenderFlexibleSection section={section} />
          </section>
        ))}

        {next.button && next.button.url && next.button.url.length > 0 && <NextPage data={next} />}
      </Layout>
    );
  }
}

const RenderFlexibleSection = ({ section }) => {
  switch (section.__typename) {
    case 'WpFeature_Featurepage_Content_ThreeColGrid':
      return (
        <section className="text-center bg-d-black text-white pad-tb--sm">
          <div className="container">
            <div className="grid md:grid-cols-3 gap-12 lg:gap-16 xl:gap-20">
              {section.items.map((item, i) => (
                <div key={item.heading + i}>
                  <div className="max-w-xs mx-auto">
                    <Image data={item.icon} className="mx-auto" style={{ maxWidth: '46px' }} />
                    <h3 className="text-32px max-w-xxs mx-auto font-semibold mt-6 mb-5">{item.heading}</h3>
                    <p>{item.content}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      );
    case 'WpFeature_Featurepage_Content_Grid':
      return <FeatureContentGrid data={section} />;
    case 'WpFeature_Featurepage_Content_CustomerStory':
      return (
        <FeaturedCustomer
          smallHeading={true}
          textContainerSize="md"
          customer={{
            textCard: {
              heading: section.heading,
              content: section.content,
              // button: {
              //   url: section.post.uri,
              //   title: 'Read the full story',
              // },
            },
            image: section.image,
            logo: section.logo,
          }}
        />
      );
    // return null;
    default:
      return null;
  }
};

export const query = graphql`
  query Feature($id: String!) {
    wpFeature(id: { eq: $id }) {
      ...FeatureSEO
      id
      title
      featurePage {
        colour
        content {
          __typename
          ... on WpFeature_Featurepage_Content_ThreeColGrid {
            items {
              heading
              content
              icon {
                ...Image
              }
            }
          }
          ... on WpFeature_Featurepage_Content_Grid {
            heading
            gridStyle
            gridItems {
              content
              layoutStyle
              heading
              image {
                ...Image
              }
            }
          }
          ... on WpFeature_Featurepage_Content_CustomerStory {
            content
            heading
            image {
              ...Image
            }
            logo {
              ...Image
            }
            post {
              __typename
              ... on WpCustomer {
                id
                uri
              }
            }
          }
        }
        hero {
          image {
            ...Image
          }
          textCard {
            button {
              target
              title
              url
            }
            content
            heading
          }
        }
        icon {
          ...Image
        }
        next {
          colour
          icon {
            ...Image
          }
          button {
            target
            title
            url
          }
        }
      }
    }
  }
`;
