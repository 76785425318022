import React from 'react';
import Image from './Image';
import chunk from 'lodash/chunk';

export default function FeatureContentGrid({ data }) {
  return (
    <section className="bg-tertiary text-black pad-tb--sm">
      <div className="container">
        <h3 className="text-center text-36px font-semibold mb-16">{data.heading}</h3>
        <div className="mx-auto" style={{ maxWidth: '997px' }}>
          {data.gridStyle === 'horizontal' && <HorGrid data={data} />}
          {data.gridStyle === 'vertical' && <VerGrid data={data} />}
          {data.gridStyle === 'reversed' && <RevGrid data={data} />}
        </div>
      </div>
    </section>
  );
}

function HorGrid({ data }) {
  return (
    <div className="flex flex-wrap -ml-4 -mb-4">
      {data.gridItems.map((item, i) => (
        <div key={item.heading + i} className={`${item.layoutStyle === 'inline' ? 'w-full' : 'md:w-1/2'} pl-4 pb-4`}>
          <GridItem item={item} />
        </div>
      ))}
    </div>
  );
}

function RevGrid({ data }) {
  return (
    <div className="flex flex-wrap -mb-4">
      {data.gridItems.map((item, i) => (
        <div key={item.heading + i} className={`bg-white w-full sm:px-12 px-8 mb-4`}>
          <div className={`flex flex-wrap items-center -ml-16`}>
            <div className={`w-full md:w-1/2 pl-16 ${i % 2 !== 0 ? 'md:order-1 ' : ' '}`}>
              <div className={`py-12 md:max-w-md ${i % 2 !== 0 ? 'md:mr-0 md:ml-auto' : ''}`}>
                <h4 className="text-28px font-semibold mb-4 mr-4">{item.heading}</h4>
                <p>{item.content}</p>
              </div>
            </div>
            <div className="w-full md:w-1/2 pl-16">
              <Image data={item.image} className="flex-auto mx-auto object-contain md:py-16 pb-16" style={{ maxHeight: '400px' }} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

function VerGrid({ data }) {
  return (
    <div className="flex flex-wrap -ml-4 -mb-4">
      {chunk(data.gridItems, 2).map((chunkItem, i) => (
        <div className="pl-4 md:w-1/2" key={`chunkItem${i}`}>
          {chunkItem.map((item, i) => (
            <div key={item.heading + i} className="pb-4">
              <GridItem item={item} />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

function GridItem({ item }) {
  return (
    <div className={`bg-white ${item.layoutStyle === 'inline' ? 'flex flex-wrap items-center' : ''}`}>
      <div className={`p-10 ${item.layoutStyle === 'inline' ? 'md:w-5/12 pr-0' : ''}`}>
        <h4 className="text-28px font-semibold mb-4 mr-4">{item.heading}</h4>
        <p>{item.content}</p>
      </div>
      <Image data={item.image} className="flex-auto mx-auto object-contain" />
    </div>
  );
}
