import React from 'react';
import Link from './Link';
import Image from './Image';

import styled from 'styled-components';
// import tw from 'twin.macro';
const HoverPoint = styled.div`
  &:hover {
    svg {
      transform: translateX(-5px);
    }
  }
`;
export default function NextPage({ data }) {
  const { colour, button, icon } = data;
  const map = {
    blue: 'accent',
  };
  return (
    <Link to={button.url} className={`block text-white bg-${map[colour] || colour}`}>
      <HoverPoint className="container py-8 flex items-center justify-center text-center">
        {icon && <Image data={icon} />}
        <h5 className="text-28px sm:text-40px font-medium ml-4 mr-8 leading-snug">{button.title}</h5>
        <div className="transform scale-75 sm:scale-100">
          <svg width="16" height="23" xmlns="http://www.w3.org/2000/svg" className="relative transition-transform duration-150" style={{ top: '2px' }}>
            <path transform="translate(1.5 1.5)" stroke="#FFF" strokeWidth="4.2" fill="none" d="M0.479070137 0.56L10.5590701 9.8 0.479070137 19.04" />
          </svg>
        </div>
      </HoverPoint>
    </Link>
  );
}
