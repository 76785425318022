import React from 'react';
import TwoColumns from './TwoColumns';
import Link from './Link';
import Image from './Image';
import Stats from './Stats';
import Testimonial from './Testimonial';

export default function FeaturedCustomer({ sectionPadding = 'lg:my-32 my-20', statsPadding = 'mt-16', customer, reverse = false, showStats = false, smallHeading = false, textContainerSize = 'sm' }) {
  return (
    <>
      <div className={sectionPadding}>
        <TwoColumns
          hasContainer={true}
          reverse={reverse}
          text={
            <div className={`text-card md:max-w-${textContainerSize} md:py-6 lg:py-12 ${reverse ? 'mx-auto' : ''}`}>
              {customer.logo && (
                <div className="py-3 px-4 mb-2 bg-black inline-block">
                  <div style={{ maxWidth: '99px' }} className="w-full">
                    <Image data={customer.logo} className="object-contain w-full" />
                  </div>
                </div>
              )}

              <h1 className={`text-card__heading ${smallHeading ? 'text-26px font-semibold' : 'text-42px'} `} style={{ lineHeight: '1.25' }} dangerouslySetInnerHTML={{ __html: customer.textCard.heading }} />

              <div className="text-card__content" dangerouslySetInnerHTML={{ __html: customer.textCard.content }} />

              {customer.textCard.button?.title.length && (
                <Link className="text-link text-link--black" to={customer.textCard.button.url}>
                  {customer.textCard.button.title}
                </Link>
              )}
            </div>
          }
          image={<Image className="object-cover" data={customer.image} />}
        />

        {showStats && customer.stats && customer.stats.length && (
          <div className={statsPadding}>
            <div className="container">
              <Stats stats={customer.stats} />
            </div>
          </div>
        )}
      </div>
      {customer.hasTestimonial === true && <Testimonial data={customer.testimonialWrapper?.testimonial} />}
    </>
  );
}
